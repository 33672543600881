import { z } from 'zod';

/** Base navigation dto schema. */
const baseNavigationDtoSchema = z.object({
	id: z.number(),
	pos: z.number(),
	parent: z.number(),
	link: z.string(),
	title: z.string(),
	newwindow: z.boolean(),
	removeable: z.boolean(),
	localurl: z.boolean(),
	style: z.string(),
	page: z.string(),
	permissions: z.array(z.string()),
});

/** Navigation dto. */
export type NavigationDto = z.infer<typeof baseNavigationDtoSchema> & {

	/** Navigation children. */
	readonly children: readonly NavigationDto[];
};

/** Navigation dto schema. */
export const navigationDtoSchema: z.ZodType<NavigationDto> = baseNavigationDtoSchema.extend({
	children: z.lazy(() => z.array(navigationDtoSchema)),
});
