import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { Router, RouterModule } from '@angular/router';
import { Navigation } from '@mei/common/core/models/navigation';

import { MenuItemComponent } from '../menu-item/menu-item.component';

const CLOSE_MENU_DELAY = 100;

/** Menu component. */
@Component({
	selector: 'meiw-menu',
	templateUrl: './menu.component.html',
	styleUrl: './menu.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, MatMenuModule, RouterModule, MatTabsModule, MenuItemComponent],
})
export class MenuComponent {
	/** Navigation. */
	@Input({ required: true })
	public navigation!: Navigation;

	private readonly router = inject(Router);

	// Need to use any because NodeJS namespace is not detected when run test. Type NodeJS.Timeout.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private timedOutCloser: any = null;

	/**
	 * Open menu.
	 * @param trigger  Menu trigger.
	 */
	public openMenu(trigger: MatMenuTrigger): void {
		if (this.timedOutCloser) {
			clearTimeout(this.timedOutCloser);
		}
		trigger.openMenu();
	}

	/**
	 * Close menu.
	 * @param trigger  Menu trigger.
	 */
	public closeMenu(trigger: MatMenuTrigger): void {
		this.timedOutCloser = setTimeout(() => {
			trigger.closeMenu();
		}, CLOSE_MENU_DELAY);
	}

	/**
	 * Check if any child route is active.
	 * @param navigationItem Navigation item.
	 */
	protected hasActiveChildren(navigationItem: Navigation): boolean {
		const currentRoute = this.router.url.split('/')[1];
		const link = navigationItem.link.replace('/', '');

		// If the current item's link matches the current route
		if (link === currentRoute) {
			return true;
		}

		// Recursively check children using `some` to short-circuit on the first match
		return navigationItem.children?.some(child => this.hasActiveChildren(child)) ?? false;
	}
}
