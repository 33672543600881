import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NotificationService } from '@mei/common/core/services/notification.service';

import { HeaderComponent } from 'projects/web/src/app/layouts/components/header/header.component';
import { NavigationComponent } from 'projects/web/src/app/layouts/components/navigation/navigation.component';
import { merge } from 'rxjs';

/** Root component. */
@Component({
	selector: 'meiw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterOutlet, HeaderComponent, NavigationComponent],
})
export class AppComponent implements OnInit {
	private readonly notificationService = inject(NotificationService);

	/** @inheritdoc */
	public ngOnInit(): void {
		// No need to handle the subscription here since it must be alive the whole life of the app.
		merge(
			this.notificationService.notification$,
		).subscribe();
	}
}
