import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SideMenuComponent } from '@mei/common/shared/components/side-menu/side-menu.component';
import { SideMenuService } from '@mei/common/core/services/side-menu.service';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { LoadingService } from '@mei/common/core/services/loading.service';
import { LoadingDirective } from '@mei/common/shared/directives/loading.directive';
import { UserService } from '@mei/common/core/services/user.service';

import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MasqueradeBarComponent } from './components/masquerade-bar/masquerade-bar.component';

/**
 * Layout component.
 */
@Component({
	selector: 'meiw-layout',
	templateUrl: './layout.component.html',
	styleUrl: './layout.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		RouterOutlet,
		HeaderComponent,
		NavigationComponent,
		SideMenuComponent,
		CommonModule,
		PortalModule,
		LoadingDirective,
		MasqueradeBarComponent,
	],
})
export class LayoutComponent {
	private readonly sideMenuService = inject(SideMenuService);

	private readonly loadingService = inject(LoadingService);

	private readonly userService = inject(UserService);

	/** Menu portal. */
	protected readonly menuPortal$ = this.sideMenuService.menuPortal$;

	/** Whether side menu is open. */
	protected readonly isOpen$ = this.sideMenuService.isOpen$;

	/** Whether app is loading or not. */
	protected readonly isLoading$ = this.loadingService.isLoading$;

	/** Current user. */
	protected readonly user$ = this.userService.currentUser$;
}
