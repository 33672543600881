import { Injectable } from '@angular/core';

import { NavigationDto } from '../dtos/navigation.dto';
import { Navigation } from '../models/navigation';

import { MapperFromDto } from './mappers';

/** Navigation mapper. */
@Injectable({
	providedIn: 'root',
})
export class NavigationMapper implements MapperFromDto<NavigationDto, Navigation> {
	/** @inheritdoc */
	public fromDto(dto: NavigationDto): Navigation {
		return {
			id: dto.id,
			position: dto.pos,
			parent: dto.parent,
			link: dto.link,
			title: dto.title,
			isNewWindow: dto.newwindow,
			isRemovable: dto.removeable,
			isLocalUrl: dto.localurl,
			style: dto.style,
			page: dto.page,
			permissions: dto.permissions,
			children: dto.children.map(this.fromDto.bind(this)),
		};
	}
}
