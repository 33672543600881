import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { NavigationMapper } from '../mappers/navigation.mapper';

import { navigationDtoSchema } from '../dtos/navigation.dto';
import { DataWithWrapperDto } from '../dtos/data-with-wrapper.dto';

import { secureParse } from '../utils/secureParse';

import { Navigation } from '../models/navigation';

import { AppUrlsConfig } from './app-urls.config';

/** Navigation service. */
@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private readonly httpClient = inject(HttpClient);

	private readonly appUrlsConfig = inject(AppUrlsConfig);

	private readonly navigationMapper = inject(NavigationMapper);

	/** Get list of navigation items. */
	public getList(): Observable<Navigation[]> {
		return this.httpClient
			.get<DataWithWrapperDto>(this.appUrlsConfig.navigation.list)
			.pipe(
				map(response => secureParse(response.data, navigationDtoSchema.array())),
				map(items => items.map(item => this.navigationMapper.fromDto(item))),
			);
	}
}
