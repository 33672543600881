import { Routes } from '@angular/router';

import { authGuard } from '@mei/common/core/guards/auth-guard';

import { PageNotFoundComponent } from '@mei/common/shared/components/page-not-found/page-not-found.component';

import { webRoutePaths } from './features/shared/web-route-paths';
import { LayoutComponent } from './layouts/layout.component';
import { ChangePasswordComponent } from './features/change-password/change-password.component';

/**
 * Routes object are described based on the created by `buildRoutePaths` object.
 * In some cases, when we define children for dynamic parameters, we must initialize
 * this parameter with an empty string, as in the example below.
 * This behavior is allowed in routing modules.
 * @example
 * ```ts
 * const routePaths = buildRoutePaths({
 *   dashboard: {
 *     path: 'dashboard',
 *     children: {
 *       auth: { path: 'auth' },
 *       users: {
 *         path: ':id',
 *         children: {
 *           edit: { path: 'edit' },
 *         },
 *       },
 *     },
 *   },
 * } as const);
 *
 * const routes: Routes = [
 *   {
 *     path: routePaths.dashboard.path,
 *     children: [
 *       {
 *         path: routePaths.dashboard.children.auth.path,
 *         component: AuthComponent,
 *       },
 *       {
 *         path: routePaths.dashboard.children.users.path,
 *         children: [
 *           {
 *             path: routePaths.dashboard.children.users.children({ id: '' }).edit.path,
 *             component: EditUserComponent,
 *           },
 *         ],
 *       },
 *     ],
 *   };
 * ];
 * ```
 */
export const appRoutes: Routes = [
	{
		path: webRoutePaths.auth.path,
		canActivate: [authGuard({ isAuthorized: false })],
		loadChildren: () => import('./features/auth/auth.routes').then(r => r.authRoutes),
	},
	{
		path: webRoutePaths.changePassword.path,
		canActivate: [authGuard({ isAuthorized: true })],
		component: ChangePasswordComponent,
	},
	{
		path: '',
		component: LayoutComponent,
		canActivate: [authGuard({ isAuthorized: true })],
		children: [
			{
				path: webRoutePaths.quoting.path,
				loadChildren: () => import('./features/quote/quote.routes').then(r => r.quoteRoutes),
			},
			{
				path: webRoutePaths.users.path,
				loadChildren: () => import('./features/user/user.routes').then(r => r.userRoutes),
			},
			{
				path: webRoutePaths.resources.path,
				loadChildren: () =>
					import('./features/resource/resource.routes').then(r => r.resourceRoutes),
			},
			{
				path: webRoutePaths.quotableItems.path,
				loadChildren: () =>
					import('./features/quotable-items/quotable-items.routes').then(r => r.quotableItemRoutes),
			},
			{
				path: webRoutePaths.workorders.path,
				loadChildren: () =>
					import('./features/workorder/workorder.routes').then(r => r.workorderRoutes),
			},
			{
				path: webRoutePaths.employeeManager.path,
				loadChildren: () => import('./features/employee-manager/employee-manager.routes').then(r => r.employeeManagerRoutes),
			},
			{
				path: '**',
				component: PageNotFoundComponent,
			},
		],
	},
];
