@if (navigation.isLocalUrl) {
	<a
		mat-tab-link
		class="menu__link"
		[routerLink]="'/' + navigation.link"
		[target]="navigation.isNewWindow ? '_blank' : '_self'"
		routerLinkActive
		#linkStatus="routerLinkActive"
		[active]="linkStatus.isActive || hasActiveChildren"
	>
		{{ navigation.title }}

		@if (navigation.children.length > 0) {
			<mat-icon>keyboard_arrow_down</mat-icon>
		}
	</a>
} @else {
	<a
		mat-tab-link
		class="menu__link"
		[href]="navigation.link"
		target="_blank"
	>
		{{ navigation.title }}

		@if (navigation.children.length > 0) {
			<mat-icon>keyboard_arrow_down</mat-icon>
		}
	</a>
}
