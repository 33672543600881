import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { PermissionApiService } from '@mei/common/core/services/permission-api.service';
import { UserService } from '@mei/common/core/services/user.service';
import { filterNull } from '@mei/common/core/utils/rxjs/filter-null';
import { injectWebAppRoutes } from 'projects/web/src/app/features/shared/web-route-paths';
import { shareReplay, switchMap } from 'rxjs';
import { NavigationService } from '@mei/common/core/services/navigation.service';

import { MenuComponent } from './components/menu/menu.component';

/** Menu. */
@Component({
	selector: 'meiw-navigation',
	templateUrl: './navigation.component.html',
	styleUrl: './navigation.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatTabsModule, MatButtonModule, RouterModule, CommonModule, MenuComponent],
})
export class NavigationComponent {
	private readonly routePaths = injectWebAppRoutes();

	private readonly permissionService = inject(PermissionApiService);

	private readonly navigationService = inject(NavigationService);

	private readonly userService = inject(UserService);

	private readonly canEditUsers$ = this.userService.currentUser$.pipe(
		filterNull(),
		switchMap(user => this.permissionService.canEditUsers(user.id)),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	/** Navigation links. */
	protected readonly navigation$ = this.navigationService.getList().pipe(shareReplay({ bufferSize: 1, refCount: true }));
}
