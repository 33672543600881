import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { Navigation } from '@mei/common/core/models/navigation';

/** Menu item component. */
@Component({
	selector: 'meiw-menu-item',
	templateUrl: './menu-item.component.html',
	styleUrl: './menu-item.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, RouterModule, MatTabsModule, MatIconModule],
})
export class MenuItemComponent {
	/** Navigation. */
	@Input({ required: true })
	public navigation!: Navigation;

	/** Has active children. */
	@Input()
	public hasActiveChildren = false;
}
