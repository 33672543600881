<nav
	mat-tab-nav-bar
	[tabPanel]="tabPanel"
	class="menu"
>
	@for (link of navigation$ | async; track link.id) {
		<meiw-menu [navigation]="link"></meiw-menu>
	}
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
