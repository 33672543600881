@if (navigation.children.length === 0) {
	<meiw-menu-item
		[navigation]="navigation"
		[hasActiveChildren]="hasActiveChildren(navigation)"
		class="menu__link--root"
	></meiw-menu-item>
} @else {
	<div
		class="menu__link--parent"
		[matMenuTriggerFor]="menu"
		#menuTrigger="matMenuTrigger"
		(mouseenter)="openMenu(menuTrigger)"
		(mouseleave)="closeMenu(menuTrigger)"
	>
		<meiw-menu-item
			[navigation]="navigation"
			[hasActiveChildren]="hasActiveChildren(navigation)"
			class="menu__link--root"
		></meiw-menu-item>
	</div>

	<mat-menu
		#menu="matMenu"
		class="menu"
		[hasBackdrop]="false"
	>
		@for (childNav of navigation.children; track childNav.id) {
			<ng-container>
				@if (childNav.children.length > 0) {
					<meiw-menu [navigation]="childNav"></meiw-menu>
				} @else {
					<div
						(mouseenter)="openMenu(menuTrigger)"
						(mouseleave)="closeMenu(menuTrigger)"
					>
						<meiw-menu-item [navigation]="childNav"></meiw-menu-item>
					</div>
				}
			</ng-container>
		}
	</mat-menu>
}
